import React from 'react';
import { Link } from 'gatsby';

const Navbar = () => (
  <footer>
    <div>
      <h2 className="title">schotter & staub</h2>
      <p>Copyright 2021 schotter & staub</p>
      <div className="links">
        <Link to="/imprint">Impressum</Link>
      </div>
    </div>
  </footer>
);

export default Navbar;
