import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Helmet from 'react-helmet';
import '../styles/global.css';


const Layout = ({children}) => (
  <div className="layout">
      <Helmet>
        <script defer data-domain="schotterundstaub.de" src="https://plausible.io/js/plausible.js"></script>
      </Helmet>
      <Navbar />
      <div className="site">
            <div className="content">
              {children}
            </div>
            <Footer />
      </div>
  </div>
);

export default Layout;
