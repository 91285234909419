import React from 'react';
import { Link } from 'gatsby';
import { FaInstagram } from 'react-icons/fa';
import { FiMail, FiYoutube } from 'react-icons/fi';


const Navbar = ({}) => (
  <nav>
    <div className="links">
      <div className="social-icons">
          <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCutWPD1sC2R7iozZy7nEVQA"><FiYoutube /></a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/schotterundstaub/"><FaInstagram /></a>
          <a href="mailto:mail@example.com"><FiMail /></a>
      </div>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/about">About</Link></li>
      </ul>
    </div>
  </nav>
);

export default Navbar;
